@import 'fonts';
@import 'layout';
@import 'variables.css';

:root {
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.help-section-popover {
  padding-top: 0.5rem;
  width: 250px;
  overflow-x: hidden;

  .menu-item {
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    background-color: var(--color-background-white);
    border-width: 0;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: var(--color-secondary-10);
    }

    .menu-item-icon {
      margin-right: 0.5rem;
    }

    .menu-item-description {
      line-height: normal;
    }
  }

  .support-email {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem 1rem 1rem;
    border-top: 1px solid var(--color-neutral-30);
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--color-neutral-80);
  }

  .support-link {
    color: var(--color-secondary-main);
    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: 1536px) {
  :root {
    font-size: 16px;
  }
}

@media screen and (max-width: 1281px) {
  :root {
    font-size: 13px;
  }
}

#root {
  height: 100%;
}

html,
body {
  font-family: EuclidSquare;
  font-weight: 400;
  font-size: 0.875rem;
  margin: 0;
  height: 100%;
}

.global-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--header-height);

  .nav-toggle-button {
    padding: 0.375rem;
    border-radius: 6px;
    border: 0.5px solid var(--color-neutral-20);
    background: var(--color-background-white);
    height: 2.25rem;
    box-sizing: border-box;
  }

  img,
  .skeleton {
    margin-left: 1rem;
    max-height: calc(var(--header-height) - 8px);
  }

  svg {
    cursor: pointer;
  }
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.hex-picker-content {
  .react-colorful {
    width: 100%;
  }
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.2);
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-white-space {
  white-space: nowrap;
}

.unset-all {
  all: unset;
}

/* Progress bar colors for the case when it loads before Stem UI tokens */
.MuiLinearProgress-colorSecondary {
  background-color: rgb(186, 229, 206) !important;

  .MuiLinearProgress-barColorSecondary {
    background-color: rgb(76, 187, 127);
  }
}

@primary-color: #4361ee;