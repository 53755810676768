@import 'variables.css';
@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes overlayTransition {
  from {
    position: relative;
    height: 100%;
  }
  to {
    position: absolute;
    height: inherit;
  }
}

@keyframes fadeOutAndHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

.global-layout {
  height: 100%;
  overflow: hidden;

  .global-header {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);

    .company-logo {
      height: 2.625rem;
      width: auto;
    }

    .global-header-toolbar {
      padding: 0 1rem;
      height: var(--header-height);
      display: flex;
      justify-content: space-between;

      .global-header-menus {
        display: flex;
        align-items: center;
        color: var(--color-default-icon);
        font-weight: 500;
        font-size: 1rem;

        a.support {
          text-decoration: none;
          color: var(--color-neutral-90);

          &:not(:last-child) {
            margin-right: 1rem;
          }

          svg {
            path:not([fill='none']) {
              fill: var(--color-neutral-100);
            }
          }
        }

        .global-header-menu {
          padding: 0.5rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 3.375rem;
          box-sizing: border-box;

          .icon-btn {
            background: transparent;
            border: none;
            cursor: pointer;
            padding: none;
            display: flex;
            align-items: center;

            .icon-dropdown {
              transition: transform 0.5s;

              &.collapsed {
                transform: rotate(0deg);
              }

              &.expanded {
                transform: rotate(180deg);
              }
            }
          }

          .header-label {
            margin-left: 0.5rem;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }

          .menu-text {
            margin-left: 4px;
            display: flex;
            align-items: center;
            color: var(--color-neutral-90);
          }

          .account-menu {
            margin-left: 4px;

            .account-name {
              color: var(--color-text-dark);
              font-size: 0.875rem;
              display: flex;
              align-items: center;

              .user-name {
                max-width: 120px;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .account-type {
              font-size: 0.75rem;
              font-weight: 400;
              background-color: var(--color-warning-50);
              line-height: 1.125rem;
              padding: 0 0.5rem;
              border-radius: 1rem;
              color: var(--color-text-white);
              flex-grow: 0;
              width: fit-content;
              font-family: Inter;
              font-weight: 500;
              max-width: 120px;
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .global-body {
    margin-top: var(--header-height);
    display: flex;
    height: calc(100% - var(--header-height));
    width: 100vw;

    .global-nav {
      flex-shrink: 0;
      position: relative;
      box-sizing: border-box;
      width: @sidebar-min-width;
      background-color: @color-navbar-bg;
      height: 100%;
      transition: width 0.5s, display 2s;

      .scrollable-content {
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100% - 3.15rem);
      }

      .global-route-name {
        margin-left: 0.875rem;
      }

      &.expanded {
        width: @sidebar-max-width;
        transition: width 0.5s, display 2s ease;

        .global-route-name {
          display: block;
          animation: fadeIn 0.5s;
        }

        .powered-by-expanded {
          animation: fadeIn 1.5s;
        }
      }

      &.contracted {
        .global-route-name {
          animation: fadeOutAndHide 0.25s ease-out;
          animation-fill-mode: forwards;
        }
      }

      &.overlay {
        animation: overlayTransition 0s 0.5s forwards;
        z-index: 1000;
        transition: width 0.5s ease;

        &:hover {
          width: @sidebar-max-width;

          .global-route-name {
            display: block;
            animation: fadeIn 0.5s;
          }

          .powered-by-expanded {
            display: block !important;
            animation: fadeIn 1.5s;
          }

          .footer-logo.no-ml {
            display: none;
          }
        }
      }

      .global-nav-group {
        padding: 0.75rem 0;
        border-bottom: 1px solid var(--color-border-light);

        .global-nav-item {
          text-decoration: none;
          width: 100%;
          box-sizing: border-box;
          display: inline-block;
          padding: 0.75rem 1rem;
          color: var(--color-text-light-1);
          font-size: 0.875rem;
          font-weight: 400;
          display: flex;
          align-items: center;

          svg {
            flex-shrink: 0;

            @media screen and (max-width: 1536px) {
              transform: scale(0.85);
            }
          }

          &.active {
            color: var(--color-text-white);
            border-right: 4px solid var(--color-secondary-10);
            background-color: var(--color-secondary-main);
          }

          a {
            text-decoration: unset;
            color: var(--color-neutral-60);
          }
        }

        &.powered-by {
          padding: 1rem;
          border-bottom: 0;
          border-top: 1px solid var(--color-border-light);
          background-color: #f5f5f5;
          position: absolute;
          bottom: 0;
          width: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;

          .powered-by-expanded {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;

            &.contracted {
              display: none;
            }
          }

          span.text {
            font-size: 0.75rem;
            font-weight: 300;
            font-style: italic;
            color: var(--color-text-light);
            flex-shrink: 0;
            flex-wrap: nowrap;
            transform: all 2s ease;
          }

          .footer-logo {
            margin-left: 6px;
            height: 1.15rem;
            width: auto;

            &.no-ml {
              margin-left: 0;
            }

            &.expanded {
              display: none;
            }
          }
        }
      }
    }

    .global-app-body {
      position: relative;
      box-sizing: border-box;
      flex-grow: 1;
      width: calc(100vh - @sidebar-min-width);
      max-width: calc(100vw - @sidebar-min-width);
      margin-left: auto;
    }
  }

  &.allow-fullscreen {
    .global-nav {
      display: none;
    }
    .global-header {
      z-index: 0;
    }
    .global-body {
      margin-top: 0;
      height: 100%;
    }
  }
}

// Animations
.fade-up {
  animation: 0.5s ease fade-up;
}

@keyframes fade-up {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-down {
  animation: 0.5s ease fade-down;
}

@keyframes fade-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(30px);
    opacity: 0;
  }
}

.fade-right {
  animation: 0.5s ease fade-right;
}

@keyframes fade-right {
  from {
    transform: translateX(-80px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-left {
  animation: 0.5s ease fade-left;
}

@keyframes fade-left {
  from {
    transform: translateX(80px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
