@import '@attentive-platform/stem-ui/lib/styles/tokens.css';

:root {
  --mb-iterations: 72;
  --header-height: 3.375rem;
  --sidebar-expanded-width: 15.625rem;
  --sidebar-contracted-width: 3.75rem;
  --toolbar-header-height: 4.5rem;
  --takeoff-sidebar-width: 380px;

  --custom-color-bg: #fdfdfd;

  /* Temporary colors, to be moved in STEM UI color palette in v2 */
  --color-light-white: #ffffffed;
  --color-border-light: #ebebeb;
  --color-default-icon: #4d4d4d;
  --color-text-light: #9e9e9e;
  --color-text-light-1: #666666;
  --color-text-dark: #010402;
  --color-navbar-bg: #f5f5f5;
}
