@font-face {
    font-family: EuclidSquare;
    src: url('/fonts/EuclidSquare-LightItalic.woff2') format('woff2'),
        url('/fonts/EuclidSquare-LightItalic.woff') format('woff'),
        url('/fonts/EuclidSquare-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic, oblique;
}
@font-face {
    font-family: EuclidSquare;
    src: url('/fonts/EuclidSquare-Light.woff2') format('woff2'), url('/fonts/EuclidSquare-Light.woff') format('woff'),
        url('/fonts/EuclidSquare-Light.otf') format('opentype');
    font-weight: 300;
}
@font-face {
    font-family: EuclidSquare;
    src: url('/fonts/EuclidSquare-RegularItalic.woff2') format('woff2'),
        url('/fonts/EuclidSquare-RegularItalic.woff') format('woff'),
        url('/fonts/EuclidSquare-RegularItalic.otf') format('opentype');
    font-style: italic, oblique;
}
@font-face {
    font-family: EuclidSquare;
    src: url('/fonts/EuclidSquare-Regular.woff2') format('woff2'),
        url('/fonts/EuclidSquare-Regular.woff') format('woff'),
        url('/fonts/EuclidSquare-Regular.otf') format('opentype');
}
@font-face {
    font-family: EuclidSquare;
    src: url('/fonts/EuclidSquare-MediumItalic.woff2') format('woff2'),
        url('/fonts/EuclidSquare-MediumItalic.woff') format('woff'),
        url('/fonts/EuclidSquare-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic, oblique;
}
@font-face {
    font-family: EuclidSquare;
    src: url('/fonts/EuclidSquare-Medium.woff2') format('woff2'), url('/fonts/EuclidSquare-Medium.woff') format('woff'),
        url('/fonts/EuclidSquare-Medium.otf') format('opentype');
    font-weight: 500;
}
@font-face {
    font-family: EuclidSquare;
    src: url('/fonts/EuclidSquare-SemiboldItalic.woff2') format('woff2'),
        url('/fonts/EuclidSquare-SemiboldItalic.woff') format('woff'),
        url('/fonts/EuclidSquare-SemiboldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic, oblique;
}
@font-face {
    font-family: EuclidSquare;
    src: url('/fonts/EuclidSquare-Semibold.woff2') format('woff2'),
        url('/fonts/EuclidSquare-Semibold.woff') format('woff'),
        url('/fonts/EuclidSquare-Semibold.otf') format('opentype');
    font-weight: 600;
}
@font-face {
    font-family: EuclidSquare;
    src: url('/fonts/EuclidSquare-BoldItalic.woff2') format('woff2'),
        url('/fonts/EuclidSquare-BoldItalic.woff') format('woff'),
        url('/fonts/EuclidSquare-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic, oblique;
}
@font-face {
    font-family: EuclidSquare;
    src: url('/fonts/EuclidSquare-Bold.woff2') format('woff2'), url('/fonts/EuclidSquare-Bold.woff') format('woff'),
        url('/fonts/EuclidSquare-Bold.otf') format('opentype');
    font-weight: bold;
}
